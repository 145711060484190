export const listToolsWithCategories = `
query listToolsWithCategories {
  listTools {
    items {
      name
      id
      image
      categories {
        items {
          category {
            id
            name
          }
        }
      }
    }
  }
}
`;

export const listCustomerToolWithDetails = `
query listCustomerToolWithDetails {

    listCustomerTools {
    items {
      id
      toolType {
        name
      }
      name
      brand
      model
      rate
      imageList
      condition
      lat
      long
      toolType {
        categoryID
      }
    }
  }

}
`;

export const getUserConversations = `
query getUserConversations($id: ID!) {

getUser(id: $id) {
    convos {
      items {
        conversation {
          id
          members
          memberIDs
          messages(limit:1, sortDirection: DESC){
          items{
          messageDate
          content
          userID
          }
          }

        }
      }
    }
  }
}
`;

export const getConvoMessages = `
query getConversation($id:ID!) {
  getConversation(id: $id) {
    messages(sortDirection:DESC) {
      items {
        createdAt
        messageDate
        content
        userID
        id
      }
    }
  }
}
`;

export const listToolReviews = `
query listToolReviews($id: ID!) {
  listToolReviews(filter: {customerToolID: {eq: $id} }) {
    items {
      comment
      createdAt
      rating
      updatedAt
      customerToolID
      user {
        fname
        lname
        profileImage
      }
    }
  }
}

`;

export const getToolsByCategory = `
query getToolsByCategory($id: ID!) {
  getCategory(id: $id) {
    tools {
      items {
        customerTools {
          items {
               brand
            condition
            toolType {
              name
              suggestedRate
            }
            id
            imageList
            lat
            long
            model
            name
            rate
            defects
          }
        }
      }
    }
  }
}

`;

export const getToolsByToolID = `
query getToolsByToolID($id:ID!) {
  getTool(id: $id) {
    customerTools {
      items {
           brand
            condition
            toolType {
              name
              suggestedRate
            }
            id
            imageList
            lat
            long
            model
            name
            rate
            defects
      }
    }
  }
}
`;

//
// export const getToolsByCategory = `
// query getToolsByCategory($id: ID!) {
//   getCategory(id: $id)  {
//      tools {
//       items {
//         customerTools {
//           items {
//             brand
//             condition
//             toolType {
//               name
//               suggestedRate
//             }
//             id
//             imageList
//             lat
//             long
//             model
//             name
//             rate
//             defects
//           }
//         }
//       }
//     }
//
//   }
// }
//
//
// `;

export const listCategoriesWithTools = `
query MyQuery {
  listCategorys {
    items {
      name
      image
      id
      tools {
        items {
          image
          name
          id
        }
      }
    }
  }
}


`;

export const listCategoriesWithImages = `
query MyQuery {
  listCategorys {
    items {
      name
      image
      id
      tools(limit: 1) {
        items {
          image
        }
      }
    }
  }
}`;

export const listPopularCategories = `
query MyQuery {
  listCategorys(limit: 10) {
    items {
      name
      image
      id
      tools(limit: 1) {
        items {
          image
        }
      }
    }
  }
}

`;

export const listExistingBookings = `
query listExistingBookings($id:ID! ) {
  listToolRentals(filter: {customerToolID: {eq: $id}}) {
    items {
      id
      bookingNumber
      bookedStartDate
      bookedEndDate
      bookingType
      tool {
        name
      }
    }
  }
}


`;
export const getUserTransactions = `
query getUserTransactions($userID:ID!) {
  listTransactions(filter: {userID: {eq:$userID},transactionStatus: {eq: SUCCESSFUL}},limit: 100000){
      items {
      date
      amount
      id
      createdAt
      transactionType      
    }
    }
}

`;
export const getUserAddress = /* GraphQL */ `
  query MyQuery($id: ID!) {
    getUser(id: $id) {
      billingAddress {
        state
        postcode
        phoneNumber
        lastName
        id
        firstName
        createdAt
        city
        addressLine2
        addressLine1
      }
    }
  }
`;

export const getUserDetails = `
query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      fname
      lname
      zipCode
      email
      number
      latitude
      longitude
      profileImage
      stripeCustomerID
      stripeConnectID
      bankAccountLinked
       profileImage
      billingAddressID
    }
  }
`;

export const getBillingInfo = `
query MyQuery($id:ID!) {
  getUser(id: $id) {
    billingAddress {
      lastName
      addressLine1
      addressLine2
      city
      createdAt
      firstName
      id
      phoneNumber
      postcode
      state
    }
    bankAccountLinked
    stripeCustomerID
    stripeConnectID
    paypalLinkedEmail

  }
}
`;

export const listUserAddresses = `
query MyQuery($id:ID!) {
  getUser(id: $id) {
    UserAddressList {
      items {
      lat
      lng
        addressLine1
        addressLine2
        city
        firstName
        id
        lastName
        phoneNumber
        postcode
        state
        userID
        id
      }
    }
  }
}

`;

export const getUserRentals = `
query MyQuery($id:ID!) {
  getUser(id: $id) {
    toolsRented {
      items {
        bookedEndDate
        bookedStartDate
        bookingType
        bookingNumber
        conversationID
        customerToolID
        daysRented
        id
        sellerID
        tool {
          name
        }
      }
    }
  }
}
`;

export const getUserSuccessfulTransactions = `
query MyQuery($id: ID!) {
  getUser(id: $id) {
    transactions(filter: {transactionStatus: {eq: SUCCESSFUL}}) {
      items {
        id
        amount
        transactionType
        transactionCategory
        createdAt
      }
    }
  }
}
`;

export const getUserLendings = `
query MyQuery($id:ID!) {
  getUser(id: $id) {
    toolsLended {
      items {
        bookedEndDate
        bookedStartDate
        bookingType
        bookingNumber
        buyerID
        conversationID
        createdAt
        customerToolID
        daysRented
        id
        sellerID
        tool {
          name
        }
      }
    }
  }
}

`;

export const listTools = /* GraphQL */ `
  query ListTools {
    listTools(limit: 10000) {
      items {
        id
        name
        image
        description
        suggestedRate
        categoryID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listExistingActiveBookings = /* GraphQL */ `
  query listExistingBookings($id: ID!) {
    listToolRentals(filter: { customerToolID: { eq: $id } }) {
      items {
        id
        bookingNumber
        bookedStartDate
        bookedEndDate
        bookingType
        tool {
          name
        }
      }
    }
  }
`;

export const listMyTools = `
query MyQuery($id:ID!) {
  getUser(id: $id) {
    userTools {
      items {
        id
        brand
        condition
        defects
        des
        lat
        long
        model
        rate
        year
        imageList
        addressID
        name
      }
    }
  }
}
`;
